import type { StoryData } from '@storyblok/react';
import { getStoryblokApi } from '@storyblok/react';
import type { GetStaticProps, InferGetStaticPropsType } from 'next';
import Link from 'next/link';
import { Fade } from 'react-slideshow-image';

import ContactBlock from '@/components/ContactBlock';
import GraphicBlackfriars from '@/components/graphics/GraphicBlackfriars';
import GraphicCardiff from '@/components/graphics/GraphicCardiff';
import GraphicEdinburgh from '@/components/graphics/GraphicEdinburgh';
import GraphicLiverpoolSt from '@/components/graphics/GraphicLiverpoolSt';
import GraphicMonument from '@/components/graphics/GraphicMonument';
import GraphicStPauls from '@/components/graphics/GraphicStPauls';
import GraphicWaterloo from '@/components/graphics/GraphicWaterloo';
import Meta from '@/components/Meta';
import Advert from '@/components/stories/Advert';
import TestimonialCard from '@/components/stories/TestimonialCard';
import Banner from '@/components/ui/Banner';
import Card from '@/components/ui/Card';
import Container from '@/components/ui/Container';
import Highlight from '@/components/ui/Highlight';

import bannerItem1 from '../../public/images/banner--london-blackfriars-serviced-offices.png';
import bannerItem2 from '../../public/images/banner--london-liverpool-street-serviced-office-entrance.jpg';
import bannerItem3 from '../../public/images/banner--london-monument-serviced-offices.png';
import bannerItem4 from '../../public/images/banner--london-st-pauls-serviced-office-lobby.jpg';
import bannerItem5 from '../../public/images/banner--london-waterloo-serviced-offices.jpg';
import bannerMobileItem1 from '../../public/images/banner--mobile--london-blackfriars-serviced-offices.png';
import bannerMobileItem2 from '../../public/images/banner--mobile--london-liverpool-street-serviced-office-entrance.jpg';
import bannerMobileItem3 from '../../public/images/banner--mobile--london-monument-serviced-offices.png';
import bannerMobileItem4 from '../../public/images/banner--mobile--london-st-pauls-serviced-office-lobby.jpg';
import bannerMobileItem5 from '../../public/images/banner--mobile--london-waterloo-serviced-offices.jpg';
import officeTile6 from '../../public/images/tile--cardiff-serviced-office.png';
import officeTile7 from '../../public/images/tile--edinburgh-serviced-office.png';
import officeTile1 from '../../public/images/tile--london-blackfriars-serviced-office.png';
import officeTile2 from '../../public/images/tile--london-liverpool-st-serviced-office.png';
import officeTile3 from '../../public/images/tile--london-monument-serviced-office.png';
import officeTile4 from '../../public/images/tile--london-st-pauls-serviced-office.png';
import officeTile5 from '../../public/images/tile--london-waterloo-serviced-office.png';
import serviceTile2 from '../../public/images/tile--meeting-rooms.png';
import serviceTile1 from '../../public/images/tile--serviced-offices.png';
import serviceTile4 from '../../public/images/tile--venues-events.png';
import serviceTile3 from '../../public/images/tile--virtual-offices.png';
import LayoutMain from '../layouts/LayoutMain';
import styles from '../styles/Index.module.css';

const Home = ({ adverts }: InferGetStaticPropsType<typeof getStaticProps>) => {
  return (
    <>
      <Meta
        title="Serviced Offices Meeting Rooms | OSiT"
        description="At Office Space in Town UK we offer serviced offices, conference rooms and virtual offices throughout the UK. Flexible fully serviced office to rent."
      />

      <Banner
        images={[
          {
            ...bannerItem1,
            alt: 'London Blackfriars serviced offices',
            url: '/serviced-offices/london-blackfriars/',
          },
          {
            ...bannerItem2,
            alt: 'London Liverpool Street serviced offices',
            url: '/serviced-offices/london-liverpool-street/',
          },
          {
            ...bannerItem3,
            alt: 'London Monument serviced offices',
            url: '/serviced-offices/london-monument/',
          },
          {
            ...bannerItem4,
            alt: 'London St Pauls serviced offices',
            url: '/serviced-offices/london-st-pauls/',
          },
          {
            ...bannerItem5,
            alt: 'London Waterloo serviced offices',
            url: '/serviced-offices/london-waterloo/',
          },
        ]}
        mobileImages={[
          {
            ...bannerMobileItem1,
            alt: 'London Blackfriars serviced offices',
            url: '/serviced-offices/london-blackfriars/',
          },
          {
            ...bannerMobileItem2,
            alt: 'London Liverpool Street serviced offices',
            url: '/serviced-offices/london-liverpool-street/',
          },
          {
            ...bannerMobileItem3,
            alt: 'London Monument serviced offices',
            url: '/serviced-offices/london-monument/',
          },
          {
            ...bannerMobileItem4,
            alt: 'London St Pauls serviced offices',
            url: '/serviced-offices/london-st-pauls/',
          },
          {
            ...bannerMobileItem5,
            alt: 'London Waterloo serviced offices',
            url: '/serviced-offices/london-waterloo/',
          },
        ]}
      />

      <section className={styles.content}>
        <Container size="xl">
          <h1>
            Themed <Link href="/serviced-offices/">serviced offices</Link>,
            designed to inspire the people in your business.
          </h1>
          <p>Looking for new office space for your team?</p>
          <p>
            You are in luck. Flexible{' '}
            <Link href="/serviced-offices/">serviced office spaces</Link> are
            our specialty. After all, the workspace industry is in our DNA.
          </p>
          <p>
            Known for their curated interior designs, best-in-class{' '}
            <Link href="/meeting-rooms/">meeting rooms</Link>, and{' '}
            <Link href="/venues-for-events/">event spaces</Link>, quality is at
            the heart of what we do in our OSiT office centres.
          </p>
          <h2>
            <Link href="/serviced-offices/">Office space</Link>,{' '}
            <Link href="/meeting-rooms/">meeting rooms</Link>,{' '}
            <Link href="/venues-for-events/">event spaces</Link>, or{' '}
            <Link href="/virtual-offices/">virtual offices</Link>. We&rsquo;ve
            got you.
          </h2>

          <div className={styles.service_cards}>
            <Card
              image={{ url: serviceTile1, alt: 'Serviced Offices' }}
              title="Serviced Offices"
              body="Exciting city centre boutique office spaces for organisations of all sizes. Dedicated office space, world class meeting rooms, and a fantastic team to support you."
              cta={{
                text: 'Find out more',
                link: '/serviced-offices/',
                color: 'secondary',
              }}
              hasShadow
            ></Card>

            <Card
              image={{ url: serviceTile2, alt: 'Meeting Rooms' }}
              title="Meeting Rooms"
              body="Best-in-class meeting spaces available to hire by the hour, for half a day, or full day. Prepare yourself! These are meeting rooms designed to spark conversation."
              cta={{
                text: 'Find out more',
                link: '/meeting-rooms/',
                color: 'secondary',
              }}
              hasShadow
            ></Card>

            <Card
              image={{ url: serviceTile3, alt: 'Virtual Offices' }}
              title="Virtual Offices"
              body="Whether it is a full virtual workspace service you need or just call handling and a virtual office address, we&rsquo;ve got you. We can be flexible with your workspace needs."
              cta={{
                text: 'Find out more',
                link: '/virtual-offices/',
                color: 'secondary',
              }}
              hasShadow
            ></Card>

            <Card
              image={{ url: serviceTile4, alt: 'Event Venues' }}
              title="Event Venues"
              body="Team party, client event, birthday party, work christmas do. You name it, we&rsquo;ve hosted it in our event spaces! Between The Deck and our larger meeting spaces, we&rsquo;ve got you covered."
              cta={{
                text: 'Find out more',
                link: '/venues-for-events/',
                color: 'secondary',
              }}
              hasShadow
            ></Card>
          </div>

          <Highlight fullWidth>
            <h3 className={styles.highlight}>
              Inspiring workspaces in{' '}
              <Link href="/london-serviced-offices/">London</Link>{' '}
              <Link href="/serviced-offices/edinburgh/">Edinburgh</Link>,
              <br />
              and <Link href="/serviced-offices/cardiff/">Cardiff</Link>
            </h3>
          </Highlight>

          <div className={styles.office_cards}>
            <Card
              image={{
                url: officeTile1,
                alt: 'London Blackfriars Serviced Offices',
              }}
              cta={{
                text: 'Find out more',
                link: '/serviced-offices/london-blackfriars/',
              }}
              hasBorder
            >
              <Link href="/serviced-offices/london-blackfriars/">
                <div className={styles.badge}>
                  <GraphicBlackfriars width={90} />
                  <div>
                    <h4>Blackfriars</h4>
                    <h5>London EC4Y</h5>
                  </div>
                </div>
              </Link>
              <p className="mx-6">
                A workspace themed on happiness with offices, meeting rooms and
                communal areas that will blow your mind.
              </p>
            </Card>

            <Card
              image={{
                url: officeTile2,
                alt: 'London Liverpool Street Serviced Offices',
              }}
              cta={{
                text: 'Find out more',
                link: '/serviced-offices/london-liverpool-street/',
              }}
              hasBorder
            >
              <Link href="/serviced-offices/london-liverpool-street/">
                <div className={styles.badge}>
                  <GraphicLiverpoolSt width={90} />
                  <div>
                    <h4>Liverpool Street</h4>
                    <h5>London EC2M</h5>
                  </div>
                </div>
              </Link>
              <p className="mx-6">
                Office space in the heart of London designed with a Monopoly
                theme?! What&rsquo;s not to love?
              </p>
            </Card>

            <Card
              image={{
                url: officeTile3,
                alt: 'London Monument Serviced Offices',
              }}
              cta={{
                text: 'Find out more',
                link: '/serviced-offices/london-monument/',
              }}
              hasBorder
            >
              <Link href="/serviced-offices/london-monument/">
                <div className={styles.badge}>
                  <GraphicMonument width={90} />
                  <div>
                    <h4>Monument</h4>
                    <h5>London EC3R</h5>
                  </div>
                </div>
              </Link>
              <p className="mx-6">
                Home to The Deck cafe and bar, amazing views of The Thames, The
                Shard and Tower Bridge, this is one of our most popular office
                space locations.
              </p>
            </Card>

            <Card
              image={{
                url: officeTile4,
                alt: "London St Paul's Serviced Offices",
              }}
              cta={{
                text: 'Find out more',
                link: '/serviced-offices/london-st-pauls/',
              }}
              hasBorder
            >
              <Link href="/serviced-offices/london-st-pauls/">
                <div className={styles.badge}>
                  <GraphicStPauls width={90} />
                  <div>
                    <h4>St Paul&apos;s</h4>
                    <h5>London EC1A</h5>
                  </div>
                </div>
              </Link>
              <p className="mx-6">
                Located in the midtown/city area with great commuter links, our
                St Pauls office centre is charming with its nod to the English
                literaries.
              </p>
            </Card>

            {adverts[0] && adverts.length > 1 && (
              <Fade arrows={false} pauseOnHover={false}>
                {adverts.map(
                  (story: StoryData) =>
                    story.content &&
                    story.content.image && (
                      <Advert
                        image={story.content.image}
                        link={story.content.link}
                        cta_title={story.content.cta_title}
                        key={story.uuid}
                      />
                    )
                )}
              </Fade>
            )}

            {adverts[0] && adverts.length === 1 && (
              <Advert
                image={adverts[0].content.image}
                link={adverts[0].content.link}
                cta_title={adverts[0].content.cta_title}
                key={adverts[0].uuid}
              />
            )}

            <Card
              image={{
                url: officeTile5,
                alt: 'London Waterloo Serviced Offices',
              }}
              cta={{
                text: 'Find out more',
                link: '/serviced-offices/london-waterloo/',
              }}
              hasBorder
            >
              <Link href="/serviced-offices/london-waterloo/">
                <div className={styles.badge}>
                  <GraphicWaterloo width={90} />
                  <div>
                    <h4>Waterloo</h4>
                    <h5>London SE1</h5>
                  </div>
                </div>
              </Link>
              <p className="mx-6">
                Our Alice and Wonderland themed centre is full of nothing but
                workspace charm, fantastic meeting spaces, and personality.
              </p>
            </Card>

            <Card
              image={{ url: officeTile6, alt: 'Cardiff Serviced Offices' }}
              cta={{
                text: 'Find out more',
                link: '/serviced-offices/cardiff/',
              }}
              hasBorder
            >
              <Link href="/serviced-offices/cardiff/">
                <div className={styles.badge}>
                  <GraphicCardiff width={90} />
                  <div>
                    <h4>Cardiff</h4>
                    <h5>Cardiff CF11</h5>
                  </div>
                </div>
              </Link>
              <p className="mx-6">
                In the heart of the beautiful Cardiff city centre, this
                beautifully converted synagogue has fantastic office spaces &
                meeting rooms.
              </p>
            </Card>

            <Card
              image={{ url: officeTile7, alt: 'Edinburgh Serviced Offices' }}
              cta={{
                text: 'Find out more',
                link: '/serviced-offices/edinburgh/',
              }}
              hasBorder
            >
              <Link href="/serviced-offices/edinburgh/">
                <div className={styles.badge}>
                  <GraphicEdinburgh width={90} />
                  <div>
                    <h4>Edinburgh</h4>
                    <h5>Edinburgh EH2</h5>
                  </div>
                </div>
              </Link>
              <p className="mx-6">
                With convenient access from all Edinburgh amenities, this
                serviced office centre is the prime location for up and coming
                businesses.
              </p>
            </Card>
          </div>
        </Container>

        <div className={styles.why}>
          <Container size="2xl">
            <Highlight fullWidth size="xl" className="lg:!pb-32">
              <h2>What our Clients say</h2>
            </Highlight>

            <div className={styles.testimonies}>
              <Container size="xl">
                <TestimonialCard
                  title="We have moved to a bigger space!"
                  intro="We are always greeted by a friendly team and a clean space. Ellie has been excellent. She has been responsive, creative, and helpful throughout the process."
                  author="Medhavi Patel"
                  company=""
                />
                <TestimonialCard
                  title="Added value to our business."
                  intro="Office Space in Town is by far the best serviced office company that is out there. Their personal service, due diligence, and professionalism is impeccable."
                  author="Semina Koutva"
                  company=""
                />
                <TestimonialCard
                  title="A great service."
                  intro="I booked with OSiT last minute and was offered the New York meeting room. The room was perfect for a board meeting and had all the IT we needed."
                  author="Wendy Midgley"
                  company="SES Engineering Services"
                />
              </Container>
            </div>
          </Container>
        </div>
      </section>

      <ContactBlock />
    </>
  );
};

Home.Layout = LayoutMain;
export default Home;

export const getStaticProps: GetStaticProps = async () => {
  const storyblokApi = getStoryblokApi();
  const advertData = await storyblokApi.get(
    `cdn/stories?starts_with=adverts/`,
    {
      version: 'published',
    }
  );
  const filteredAds = advertData.data.stories.filter((advert: StoryData) => {
    return advert.content.location === 'home';
  });

  const popupData = await storyblokApi.get(`cdn/stories?starts_with=popups/`, {
    version: 'published',
  });
  const filteredPopups = popupData.data.stories.filter((popup: StoryData) => {
    const pages = popup.content.Pages.split(/\r?\n/);
    return pages.includes('*') || pages.includes('/');
  });

  return {
    props: {
      adverts: filteredAds,
      popups: filteredPopups,
    },
    revalidate: 120,
  };
};
